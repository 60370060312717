body {
  font-family: "sans-serif";
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

html,
body {
  height: 100%;
}

header {
  background-color: #000;
  color: #da6505;
  padding: 20px 0;
  text-align: center;
  background-image: url("/public/header.png");
  background-size: cover;
  background-position: center 60%;
  animation: fadeIn 2s ease-in-out;
}

header h1 {
  margin: 0;
  font-size: 3rem;
  text-shadow: 2px 2px 4px #000;
  color: #da6505;
  animation: fadeInDown 2s ease-in-out;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #da6505;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: "sans-serif";
  text-shadow: 1px 1px 2px #000;
}

nav ul li a:hover {
  text-decoration: underline;
}

footer {
  background-color: #000;
  color: #da6505;
  text-align: center;
  padding: 10px 0;
  position: relative;
  width: 100%;
  animation: fadeIn 2s ease-in-out;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  overflow-x: hidden;
}

h1 {
  font-size: 2rem;
  color: #da6505;
  animation: fadeInUp 2s ease-in-out;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  animation: fadeIn 2s ease-in-out;
}

ul {
  padding: 0;
}

ul li {
  list-style: none;
  margin: 10px 0;
  animation: fadeInUp 2s ease-in-out;
}

ul li a {
  color: #1a73e8;
  text-decoration: none;
  font-size: 1.1rem;
}

ul li a:hover {
  text-decoration: underline;
}

.contact-page a {
  color: #1a73e8;
  text-decoration: none;
}

.contact-page a:hover {
  text-decoration: underline;
}

.home-banner {
  width: 100%;
  height: 400px;
  background-size: 99%;
  background-position: -180% 25%;
  margin-bottom: 20px;
  background-image: url("/public/1.jpeg");
  animation: fadeIn 2s ease-in-out;
}

.about-banner {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center 35%;
  margin-bottom: 20px;
  background-image: url("/public/2.jpeg");
  animation: fadeIn 2s ease-in-out;
}

.videos-banner {
  width: 100%;
  height: 400px;
  background-size: 99.9%;
  background-position: -980% 35%;
  margin-bottom: 20px;
  background-image: url("/public/3.jpeg");
  animation: fadeIn 2s ease-in-out;
}

.contact-banner {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
  background-image: url("/public/4.jpeg");
  animation: fadeIn 2s ease-in-out;
}

/* Keyframes for Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive design for phones and tablets */
@media (max-width: 768px) {
  header h1 {
    font-size: 2rem;
  }

  nav ul li {
    margin: 0 10px;
  }

  nav ul li a {
    font-size: 1rem;
  }

  .container {
    padding: 10px;
  }

  .home-banner,
  .about-banner,
  .videos-banner,
  .contact-banner {
    height: 200px;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
    margin: 0 10px;
  }

  ul li a {
    font-size: 1rem;
  }
}
